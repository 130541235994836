import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { string } from "prop-types"
import { logger } from "../config/pino"
import { getTokenMetadata } from "../services/backend"
import { NFTMiniCard } from "../components/NFTMiniCard"

export default function NFTImage({ data, location }) {
  // Query gatsby config to pull the backend URL to use for this page.
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          backendURL
          defaultContract
        }
      }
    }
  `)
  const backend = !!site.siteMetadata
    ? site.siteMetadata.backendURL
    : "http://localhost:3000"
  // Parse contract address and token ID from path
  // First, remove trailing slash if it exists
  let path = !!site.siteMetadata
    ? site.siteMetadata.defaultContract + "/1"
    : "0x0000000000000000000000000000000000000000/1"
  logger.debug(` path: ${path}`)
  path = location.pathname
  logger.debug(` path: ${path}`)
  const params = path.split("-")
  logger.debug(params)
  const contractAddress = params[2]
  const tokenId = params[3].split('.')[0]

  // Struct for storing the JSON response from the metadata server.
  const [tokenMetadata, setTokenMetadata] = useState({
    attributes: [{ trait_type: "", value: "" }],
    title: string,
    description: string,
    external_url: string,
    image: string,
    name: string,
    address: string,
    tokenId: string,
    EIPXXX: {
      public_key: "",
      validator_index: "",
      document: "",
      withdrawal_key: "",
    },
  })

  // Effect runs on page render, calls the metadata server for data on this NFT and saves it to state.
  useEffect(() => {
    logger.info(
      `Querying metadata API for contract: ${contractAddress} and token: ${tokenId}`
    )
    getTokenMetadata(contractAddress, tokenId, backend)
      .then(response => {
        // Confirm we received a response and that it is not an error response
        if (!!response && !("error" in response)) {
          logger.info(
            `API has returned token metadata: ${JSON.stringify(response)}`
          )
          setTokenMetadata(response)
        } else {
          logger.warn(
            `GetTokenMetadata Service returned a falsy value rather than an object:`
          )
          logger.warn(response)
        }
      })
      .catch(err => {
        logger.warn(`Exception thrown retrieving token metadata from server:`)
        logger.warn(err)
      })
  }, [contractAddress, tokenId, backend])

  console.log(tokenMetadata)

  return (
    <>
        <NFTMiniCard
          metadata={tokenMetadata}
        />
    </>
  )
}